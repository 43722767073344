
	import { Component, Vue } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IntersectVideoAutoplay from '@pixcap/ui-core/plugins/intersect-component/IntersectVideoAutoplay.vue';
	import EditColorBanner from '@/components/banners/EditColorBanner.vue';

	@Component({
		name: 'InteractiveBannerSection',
		components: { EditColorBanner, IntersectVideoAutoplay, BodyText, HeadingText },
	})
	export default class InteractiveBannerSection extends Vue {}
