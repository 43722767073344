
	import { Component, Vue } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IntersectVideoAutoplay from '@pixcap/ui-core/plugins/intersect-component/IntersectVideoAutoplay.vue';
	import { mapState } from 'vuex';
	import { AppState, NAMESPACE as APP_NAMESPACE } from '@pixcap/ui-core/models/store/app.interface';

	@Component({
		name: 'EditColorBanner',
		components: { IntersectVideoAutoplay, BodyText, HeadingText },
		computed: {
			...mapState(APP_NAMESPACE, {
				locale: (state: AppState) => state.locale,
			}),
		},
	})
	export default class EditColorBanner extends Vue {
		locale: AppState['locale'];

		colorOptions = [
			{
				title: this.$t('page.home.interactive_banner.default'),
				class: 'color--default',
				value: 'default',
			},
			{
				title: `${this.$t('page.home.interactive_banner.color')} 1`,
				class: 'color--black-orange',
				value: 'color-1',
			},
			{
				title: `${this.$t('page.home.interactive_banner.color')} 2`,
				class: 'color--purple-green ',
				value: 'color-2',
			},
			{
				title: `${this.$t('page.home.interactive_banner.color')} 3`,
				class: 'color--purple-orange',
				value: 'color-3',
			},
		];

		activeColor = this.colorOptions[0];
		componentKey = 0;
		overlaySrc = `${process.env.ROOT_URL}/cdn/explore/banner/edit-color-banner/color-overlay.webp`;

		get tryOutImgUrl() {
			if (typeof window !== 'undefined') {
				if (window.innerWidth >= 1300) {
					return `${process.env.ROOT_URL}/cdn/explore/banner/edit-color-banner/indicator/${this.locale ? this.locale : 'en'}/desktop.webp`;
				} else if (window.innerWidth < 1300 && window.innerWidth > 940) {
					return `${process.env.ROOT_URL}/cdn/explore/banner/edit-color-banner/indicator/${this.locale ? this.locale : 'en'}/tablet.webp`;
				} else {
					return `${process.env.ROOT_URL}/cdn/explore/banner/edit-color-banner/indicator/${this.locale ? this.locale : 'en'}/mobile.webp`;
				}
			}

			return `${process.env.ROOT_URL}/cdn/explore/banner/edit-color-banner/indicator/${this.locale ? this.locale : 'en'}/desktop.webp`;
		}

		get posterUrl() {
			switch (this.activeColor.value) {
				case 'color-1':
					return `${process.env.ROOT_URL}/cdn/explore/banner/edit-color-banner/color-1-poster.webp`;
				case 'color-2':
					return `${process.env.ROOT_URL}/cdn/explore/banner/edit-color-banner/color-2-poster.webp`;
				case 'color-3':
					return `${process.env.ROOT_URL}/cdn/explore/banner/edit-color-banner/color-3-poster.webp`;
				case 'default':
				default:
					return `${process.env.ROOT_URL}/cdn/explore/banner/edit-color-banner/color-default-poster.webp`;
			}
		}

		get thumbnailUrl() {
			switch (this.activeColor.value) {
				case 'color-1':
					return `${process.env.ROOT_URL}/cdn/explore/banner/edit-color-banner/color-1.webm`;
				case 'color-2':
					return `${process.env.ROOT_URL}/cdn/explore/banner/edit-color-banner/color-2.webm`;
				case 'color-3':
					return `${process.env.ROOT_URL}/cdn/explore/banner/edit-color-banner/color-3.webm`;
				case 'default':
				default:
					return `${process.env.ROOT_URL}/cdn/explore/banner/edit-color-banner/color-default.webm`;
			}
		}

		handleChangeColor(color) {
			this.activeColor = color;
			this.componentKey += 1;
		}
	}
